import React,{ useState,useEffect} from "react";
import {useDispatch,useSelector} from 'react-redux'
import { apiAction,toastAction,toastActionAlert,userAction } from "../../../customRedux/actions/Actions";
import {toastConstant,apiConstant,userConstant,HRuserConstant} from '../../../customRedux/constants/actionConstant'
import {apiPost,apiFormDatePost} from '../../../api/apiCommon'
import { useHistory,useParams  } from "react-router-dom";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'
import { saveAs } from 'file-saver'
import { ColorRing } from  'react-loader-spinner'



const OnboardView =()=>
{
    const [isVisible,setIsVisible]=useState(true);

    const { id } = useParams();
    const reduxState=useSelector(state=>state);
    const dispatch=useDispatch();
    const history = useHistory();
    const [rowData, setrowData] = useState({});
    const [imageDataURL, setimageDataURL] = useState('');
    //const [filePath, setFilePath] = useState(process.env.REACT_APP_FILE_URL);
    const [showModelViewer,setShowModelViewer]=useState();
    const [imgViewFile, setImgViewFile] = useState(null);
    const [resumeFlag, setResumeFlag] = useState(0);
    
    const [videoViewer, setVideoViewer] = useState(false);
    const [videoViewFile, setVideoViewFile] = useState(null);

    const openVIDEOModel=(name)=>
    {
       
          console.log(name);
          setVideoViewFile(name);
       
       setVideoViewer(true);
    }
    const [storevideoFile, setstorevideoFile] = useState(null);
    const storevideoFileChange = (file) => {
       if(file.length!=0){setstorevideoFile(file)};
    };

    const handleClick=(URL)=>
  {
    setImgViewFile(URL);
   // setImgViewFile(true);
    setShowModelViewer(true);
  
  }

  const downloadImage = async (url, name) => {
   
    saveAs(url, name)

    
  }
    useEffect(() => {
        getEmpList();
        getResumeList();
      
      }, []);

      const getEmpList=async ()=>
      {
       
        try{
            
            const response= await   apiPost('admin/getonboardbyid', {userUID:reduxState.userReducer.userUID,
            roleUID:reduxState.userReducer.roleUID,
                    id:id});
            if(!response.data.error)
            {
                setrowData(response.data.empDetails[0]);
                setimageDataURL(response.data.ImageURL);
                setIsVisible(false);
                
            }
            else
            { 
                dispatch(toastAction(toastConstant.setToast,response.data.errorMessage));
                dispatch(toastAction(toastConstant.errorToast));
            }
           
         }
         catch(error) {
               dispatch(toastAction(toastConstant.setToast,'Internal Server Error'));
               dispatch(toastAction(toastConstant.errorToast));
               console.log(error);
        }
      }
      const [resData, setresData] = useState({});

      const getResumeList=async ()=>
      {
       
        try{
            
            const response= await   apiPost('admin/getresumebyid', {userUID:reduxState.userReducer.userUID,
            roleUID:reduxState.userReducer.roleUID,
                   id:parseInt(id) });
            if(!response.data.error)
            {
                setresData(response.data.empDetails);
                setimageDataURL(response.data.ImageURL);
                setResumeFlag((response.data.empDetails.length>0)?1:0)
            
            }
            else
            { 
                /* dispatch(toastAction(toastConstant.setToast,response.data.errorMessage));
                dispatch(toastAction(toastConstant.errorToast)); */
            }
           
         }
         catch(error) {
              /*  dispatch(toastAction(toastConstant.setToast,'Internal Server Error'));
               dispatch(toastAction(toastConstant.errorToast));
               console.log(error); */
        }
      }
   
   return(
    <div className="wrapper">
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',position:'absolute',left:'47%',height:'40vh',zIndex:'999999' }}>
    <ColorRing
    visible={isVisible}
    height="80"
    width="80"
    ariaLabel="blocks-loading"
    wrapperStyle={{}}
    wrapperClass="blocks-wrapper"
    colors={['#283179','#283179','#283179','#283179','#283179']}
    style={{left:'50%', top:'50%'}}
    />
    </div>
    <div className={`container-fluid ${isVisible ? 'blur' : ''}`}>

       
        <div className="row">
            <div className="col-sm-12">
                <div className="page-title-box">
                    
                    <h4 className="page-title">View Employee Details</h4>
                    
                    <a href="#" onClick={e=>history.goBack()} style={{margin:'1%', float:'right'}} className="btn btn-sm btn-primary">Back</a>
                </div>
            </div>
        </div>
       
        <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                            <h4 className="mt-0 header-title">Employee Details </h4>
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="p-20">
                                            <div className="form-group m-b-0">
                                             <label> Employee Name : </label>
                                            </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>{rowData.employeeName}</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                        <label>Store Code :</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>{rowData.storeCode}</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                        <label>Store Name :</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                            <div className="form-group m-b-0">
                                                <label>{rowData.storeName}</label>
                                            </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                        <label>Designation :</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                            <div className="form-group m-b-0">
                                                <label>{rowData.designation}</label>
                                            </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                        <label>Date of Joining :</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                            <div className="form-group m-b-0">
                                                <label>{rowData.dateOfJoin}</label>
                                            </div>
                                        </div> 
                                    </div> 
                                </div> 
                            </div> 
                        </div> 
                    </div> 

                    
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                            <h4 className="mt-0 header-title">Personal Details </h4>
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="p-20">
                                            <div className="form-group m-b-0">
                                                <label>Age :</label>
                                            </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>{rowData.age}</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>Gender :</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>{rowData.gender}</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>Mobile Number :</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>{rowData.mobileNumber}</label>
                                                </div>
                                        </div> 
                                    </div>
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>Personal Email id :</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>{rowData.email}</label>
                                                </div>
                                        </div> 
                                    </div>
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>Qualification :</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>{rowData.qualification}</label>
                                                </div>
                                        </div> 
                                    </div>
                                </div> 
                            </div> 
                        </div> 
                    
                    </div> 

                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                            <h4 className="mt-0 header-title">Job Details </h4>
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="p-20">
                                            <div className="form-group m-b-0">
                                                <label>Current Organisation :</label>
                                            </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>{rowData.currentOrg}</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>Current net inhand :</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>{rowData.currentNetInhand}</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>Proposed net inhand :</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>{rowData.proposedNetInhand}</label>
                                                </div>
                                        </div> 
                                    </div>
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>Replacement/budgate   :</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>{rowData.budgate}</label>
                                                </div>
                                        </div> 
                                    </div>
                                    {rowData.budgate == 'Replacement' ? (
          
                                        <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>Replacement Name :</label>
                                                </div>
                                        </div> 
                                    </div> 
      
                                     ) : null}
                                     {rowData.budgate == 'Replacement' ? (
          
                                        <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>{rowData.replacementName}</label>
                                                </div>
                                        </div> 
                                    </div>

                                    ) : null}
                                    {rowData.budgate == 'Replacement' ? (
          
                                        <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>Replacement Code :</label>
                                                </div>
                                        </div> 
                                    </div> 

                                    ) : null}
                                    {rowData.budgate == 'Replacement' ? (

                                        <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>{rowData.replacementCode}</label>
                                                </div>
                                        </div> 
                                    </div>

                                    ) : null}

                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>Weekly off:</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>{rowData.weeklyOff}</label>
                                                </div>
                                        </div> 
                                    </div>
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>Shift timing :</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                         <label>{rowData.shiftTiming}</label>
                                        </div>
                                        </div> 
                                    </div>
                                </div> 
                            </div> 
                        </div> 
                    
                    </div> 
                    </div>

                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                            <h4 className="mt-0 header-title">OTHER DETAILS</h4>
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="p-20">
                                            <div className="form-group m-b-0">
                                                <label>Refered By :</label>
                                            </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>{rowData.referredBy}</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>Manager Name :</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>{rowData.managerName}</label>
                                                </div>
                                        </div> 
                                    </div> 
                                </div> 
                            </div> 
                        </div> 
                    
                    </div> 
                 

    
                  
                  <Modal
          size="xl"
        show={videoViewer}
        onHide={() => setVideoViewer(false)}
        aria-labelledby="xample-modal-sizes-title-lg"
        >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
          <center>  Video Viewer</center>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <video style={{maxWidth:'100%',minWidth:'100%',height:'65vh'}}  type="video/mp4" src={videoViewFile} controls/>
        </Modal.Body>
        <Modal.Footer>
        <button   onClick={() => setVideoViewer(false)} type="button" className="btn btn-primary waves-effect waves-light">
                     Close
                     </button> 
                   
        </Modal.Footer>
      </Modal>
                    <Modal
      show={showModelViewer}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Image View
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <img style={{maxWidth:'100%',minWidth:'100%'}} src={imgViewFile} />
      </Modal.Body>
      <Modal.Footer>
      <button   onClick={() => setShowModelViewer(false)} type="button" className="btn btn-danger waves-effect waves-light">
                     Close
                     </button> 
      </Modal.Footer>
    </Modal>
            </div>
             
        </div>
   );
}
export default OnboardView;