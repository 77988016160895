import { useState,useEffect} from "react";
import {useDispatch,useSelector} from 'react-redux'
import {apiAction,toastAction,toastActionAlert} from '../../../customRedux/actions/Actions'
import {toastConstant,apiConstant} from '../../../customRedux/constants/actionConstant'
import {apiPost,apiFormDatePost} from '../../../api/apiCommon'
import { useHistory ,useParams} from "react-router-dom";

import { pdfjs } from "react-pdf";

import 'react-alert-confirm/lib/style.css';
import AlertConfirm from 'react-alert-confirm';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.js`;

//redux action  and constants

//file types
const imgFileTypes = ["JPG","JPEG", "PNG"];
const docFileTypes = ["PDF", "DOCX","DOC"];
const vidFileTypes = ["MP4","MOV","AVI","WMV"];

const  EditOnboarding=()=>
{
    const { id } = useParams();
    const [rowData, setrowData] = useState({});

   const dyear = new Date();
   let ddyear = dyear.getFullYear();
   const reduxState=useSelector(state=>state);
   const dispatch=useDispatch();
   const history = useHistory();
   useEffect(() => 
   {
      window.menuFunctionVariable.init();
      window.addEventListener('resize', async function() {
           
         var innerWidth=window.innerWidth?window.innerWidth:null;
         if(innerWidth!=null)
         { 
            if(innerWidth>=992)
            {
               document.getElementById("navigation").style.display = "block";
            }
            else if(innerWidth<=990)
            {
               document.getElementById("navigation").style.display = "none";
            }
         }
       });
       //getStateList();
    }, []);
    const  formatDate=(date)=> {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
   const [employeename, setEmployeename] = useState("");
   const [storecode, setStorecode] = useState("");
   const [storename, setStorename] = useState("");
   const [designation, setDesignation] = useState("");
   const [dateofjoin, setDateofjoin] = useState("");
   const [age, setAge] = useState("");
   const [gender, setGender] = useState("");
   const [mobileno, setMobileno] = useState("");
   const [emailid, setEmailid] = useState("");
   const [qualification, setQualification] = useState("");
   const [currentorg , setCurrentorg] = useState("");
   const [currentnet , setCurrentnet] = useState("");
   const [proposednet , setProposednet] = useState("");
   const [budgate , setBudgate] = useState("");
   const [replacecode , setReplacecode] = useState("");
   const [replacename , setReplacename] = useState("");
   const [weeklyoff , setWeeklyoff] = useState("");
   const [shift , setShift] = useState("");
   const [referredby , setReferredby] = useState("");
   const [manager , setManager] = useState("");
   const [btnLoader , setbtnLoader] = useState(false);
   useEffect(() => {
    getEmpList();
  
  }, []);
  const getEmpList=async ()=>
      {
       
        try{
            
            const response= await   apiPost('admin/getonboardbyid', {userUID:reduxState.userReducer.userUID,
            roleUID:reduxState.userReducer.roleUID,
                    id:id});
            if(!response.data.error)
            {
                setrowData(response.data.empDetails[0]);
                setEmployeename(response.data.empDetails[0].employeeName);
                setStorecode(response.data.empDetails[0].storeCode);
                setStorename(response.data.empDetails[0].storeName);
                setDesignation(response.data.empDetails[0].designation);
                setDateofjoin(formatDate(new Date(response.data.empDetails[0].dateOfJoin)));
                setAge(response.data.empDetails[0].age);
                setGender(response.data.empDetails[0].gender);
                setMobileno(response.data.empDetails[0].mobileNumber);
                setEmailid(response.data.empDetails[0].email);
                setQualification(response.data.empDetails[0].qualification);
                setCurrentorg(response.data.empDetails[0].currentOrg);
                setCurrentnet(response.data.empDetails[0].currentNetInhand);
                setProposednet(response.data.empDetails[0].proposedNetInhand);
                setBudgate(response.data.empDetails[0].budgate);
                setReplacecode(response.data.empDetails[0].replacementCode);
                setReplacename(response.data.empDetails[0].replacementName);
                setWeeklyoff(response.data.empDetails[0].weeklyOff);
                setShift(response.data.empDetails[0].shiftTiming);
                setReferredby(response.data.empDetails[0].referredBy);
                setManager(response.data.empDetails[0].managerName);
                
                               
            }
            else
            { 
                dispatch(toastAction(toastConstant.setToast,response.data.errorMessage));
                dispatch(toastAction(toastConstant.errorToast));
            }
           
         }
         catch(error) {
               dispatch(toastAction(toastConstant.setToast,'Internal Server Error'));
               dispatch(toastAction(toastConstant.errorToast));
               console.log(error);
        }
      }
   const formSubmitConfirmation=(event)=>
   {
      event.preventDefault();
     
       AlertConfirm({
         title: 'Confirm to submit?',
         desc: 'Are you sure to do this...!',
         onOk: () => {
            update()
         },
         onCancel: () => {
           console.log('cancel');
         }
       });
   }

   const [isVisible, setIsVisible] = useState(false);
   const changeHandler = (e) => {
      setBudgate(e.target.value);
     if (e.target.value === 'Replacement') {
       setIsVisible(true);
     
     } else {
       setIsVisible(false);
     }
   };

  const update=async ()=>
  {
      dispatch(apiAction(apiConstant.setApiStart,{apiName:'updateonboarding',loader:true,notificationMessage:true})); 
      setbtnLoader(true);

      let formData = new FormData();   
      formData.append('id', id);
      formData.append('employeename',employeename);   
      formData.append('storecode', storecode);
      formData.append('storename', storename);
      formData.append('designation', designation);
      formData.append('dateofjoin', dateofjoin);
      formData.append('age', age);
      formData.append('gender', gender);
      formData.append('mobileno', mobileno);
      formData.append('emailid', emailid);
      formData.append('qualification', qualification);
      formData.append('currentorg', currentorg);
      formData.append('currentnet', currentnet);
      formData.append('proposednet', proposednet);
      formData.append('budgate', budgate);
      formData.append('replacementname', replacename);
      formData.append('replacementcode', replacecode);
      formData.append('weeklyoff', weeklyoff);
      formData.append('shift', shift);
      formData.append('referredby', referredby);
      formData.append('manager', manager);
      formData.append('userUID', reduxState.userReducer.userUID);


      try{
         
         const response= await  apiPost('updateonboarding', formData);
         if(!response.data.error)
         {
            dispatch(apiAction(apiConstant.setApiSuccess,{loader:false,errorCode:0,errorMessae:'',notificationMessage:false}));
            setbtnLoader(false);
            history.push("/corporate/manager/onboarding");
            
         }
         else{
            setbtnLoader(false);
            errorMessage(response.data.errorMessage);
         }
      
      }
      catch(error) 
      {
         console.log(error);
         errorMessage('Internal Server Error');
      }
  }

  const NameValidation=(event)=>
  {
    //accept alphabets only
    if(!((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)||(event.charCode==32)||event.charCode==46))
    {
       event.preventDefault();
    } 
  }

  const qualificationValidation=(event)=>
  {
    //accept alphabets only
    if(!((event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)||(event.charCode==32)||event.charCode==46||event.charCode==44))
    {
       event.preventDefault();
    }
 
 
     
  }
  const mobile=(event)=>
  {
    //accept alphabets only
    if(!((event.charCode > 47 && event.charCode < 58) ))
    {
       event.preventDefault();
    }
   }

   const current=(event)=>
   {
     //accept alphabets only
     if(!((event.charCode > 47 && event.charCode < 58) ) )
     {
        event.preventDefault();
     }
    }
   const Age=(event)=>
  {
    //accept alphabets only
    if(!((event.charCode > 47 && event.charCode < 58) ))
    {
       event.preventDefault();
    }
   }
  const resetForm=()=>
  {
   
  }
  const errorMessage=(msg)=>
  {
   dispatch(toastAction(toastConstant.setToast,msg));
   dispatch(toastAction(toastConstant.errorToast));
   dispatch(apiAction(apiConstant.setApiFailure,{loader:false,errorCode:1,errorMessae:'',notificationMessage:false}));
  }

    return (
      <>
   <div className="wrapper">
      <div className="container-fluid">
     
         <form onSubmit={formSubmitConfirmation}>
         <div className="row">
            <div className="col-sm-12">
                <div className="page-title-box">
                    
                    <h4 className="page-title">Edit Talent Acquisition Details</h4>
                    
                    <a href="#" onClick={e=>history.goBack()} style={{margin:'1%', float:'right'}} className="btn btn-sm btn-primary">Back</a>
                </div>
            </div>
        </div>
         <div className="row">
            <div className="col-12">
               <div className="card">
                  <div className="card-body">
                    
                     <div className="row">
  


 
   <div className="col-lg-12">
   <div className="card">
     
         <div className="row">
            <div className="col-xl-12">
               
            <h4 className="mt-0 header-title">EMPLOYEE DETAILS</h4>
            </div>
         </div>
         <div className="row mt-3" >
       
         <div className="col-xl-4 " >
               <div className="card">
                
                     <h4 className="mt-0 header-title">EMPLOYEE NAME<span className="inputImportantRed">*</span></h4>
                     <div className="form-group">
                        <input type="text"  onKeyPress={e=>NameValidation(e)}  value={employeename}  onChange={e=>setEmployeename(e.target.value)} placeholder="Enter your Name" className="form-control" name="employeename" id="employeename"  tabIndex="1" required />   
                     </div>

               </div>
            </div>
           
            <div className="col-xl-4">
               <div className="card">
                 
                     <h4 className="mt-0 header-title">STORE CODE<span className="inputImportantRed">*</span></h4>
                     <div className="form-group">
                        <input type="text"     value={storecode}  onChange={e=>setStorecode(e.target.value)} placeholder="Enter Store Code" className="form-control" name="storecode" id="storecode"  tabIndex="1" required />   
                     </div>
                 
               </div>
            </div>
           
            <div className="col-xl-4">
               <div className="card">
                  
                     <h4 className="mt-0 header-title">STORE NAME<span className="inputImportantRed">*</span></h4>
                     <div className="form-group">
                        <input type="text"    value={storename}  onChange={e=>setStorename(e.target.value)} placeholder="Enter Store Name" className="form-control" name="storename" id="storename"  tabIndex="1" required />   
                     </div>
                 
               </div>
            </div>
            <div className="col-xl-4">
               <div className="card">
                 
                     <h4 className="mt-0 header-title">Designation
                        <span className="inputImportantRed">*</span></h4>
                     <div className="form-group">
                     <select className="form-control" name="designation" value={designation} onChange={e=>setDesignation(e.target.value)} tabIndex="11" required >
                        <option value="">Select Designation</option>
                        <option value="Team Leader">Team Leader</option>
                        <option value="Store Manager">Store Manager</option>
                        <option value="Senior Fashion Consultant">Senior Fashion Consultant</option>
                        <option value="Fashion Consultant">Fashion Consultant</option>
                        <option value="Senior Sales Executive">Senior Sales Executive</option>
                        <option value="Sales Executive">Sales Executive</option>
                     </select> 
                        
                     </div>
                  
               </div>
            </div>
            <div className="col-xl-4">
               <div className="card">
               
                     <h4 className="mt-0 header-title">DATE OF JOINING <span className="inputImportantRed">*</span></h4>
                     <div className="form-group">
                     <input type="date" value={dateofjoin} onChange={e=>setDateofjoin(e.target.value)}  className="form-control" placeholder="2017-06-04" id="dateofjoin" name="dateofjoin" tabIndex="2" required />  
                     </div>
                  
               </div>
            </div>
            
         </div>
     
   </div>
</div>


   <div className="col-lg-12">
   <div className="card">
     
         <div className="row">
            <div className="col-xl-12">
            <h4 className="mt-0 header-title">Personal Details  </h4>
            </div>
         </div>
         <div className="row mt-3">
       
         <div className="col-xl-4">
               <div className="card">
                 
                     <h4 className="mt-0 header-title">Age<span className="inputImportantRed">*</span></h4>
                     <div className="form-group">
                        <input type="text"  onKeyPress={e=>Age(e)} minLength={2} maxLength={2}  value={age}  onChange={e=>setAge(e.target.value)} placeholder="Enter your Age" className="form-control" name="age" id="age"  tabIndex="1" required />   
                     </div>
                  
               </div>
            </div>
           
            <div className="col-xl-4">
               <div className="card">
                  
                     <h4 className="mt-0 header-title">Gender<span className="inputImportantRed">*</span></h4>
                     <div className="form-group">
                     <select className="form-control" name="gender" value={gender} onChange={e=>setGender(e.target.value)} tabIndex="11" required >
                        <option value="">Select Gender</option>
                        <option value="Male" >Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                     </select> 
                     </div>
                  
               </div>
            </div>
           
            <div className="col-xl-4">
               <div className="card">
                 
                     <h4 className="mt-0 header-title">Mobile Number<span className="inputImportantRed">*</span></h4>
                     <div className="form-group">
                        <input type="text" onKeyPress={e=>mobile(e)} minLength={10} maxLength={10}  value={mobileno}  onChange={e=>setMobileno(e.target.value)} placeholder="Enter your Mobile Number" className="form-control" name="mobileno" id="mobileno"  tabIndex="1" required />   
                     </div>
                  
               </div>
            </div>

            <div className="col-xl-4">
               <div className="card">
                 
                     <h4 className="mt-0 header-title">PERSONAL EMAIL ID<span className="inputImportantRed">*</span></h4>
                     <div className="form-group">
                        <input type="text"    value={emailid}  onChange={e=>setEmailid(e.target.value)} placeholder="Enter your Personal Email ID" className="form-control" name="emailid" id="emailid"  tabIndex="1" required />   
                     </div>
                
               </div>
            </div>

            <div className="col-xl-4">
               <div className="card">
                  
                     <h4 className="mt-0 header-title">QUALIFICATION<span className="inputImportantRed">*</span></h4>
                     <div className="form-group">
                       
                        <select className="form-control" name="qualification" id="qualification" value={qualification} onChange={e=>setQualification(e.target.value)} tabIndex="11" required >
                        <option value="">Select Qualification</option>
                        <option value="Matriculate/SSC" >Matriculate/SSC</option>
                        <option value="HSC">HSC</option>
                        <option value="Graduate">Graduate</option>
                        <option value="Post Graduate">Post Graduate</option>
                     </select>  
                     </div>
                  
               </div>
            </div>

     
         </div>
     
   </div>
</div>
 
<div className="col-lg-12">
   <div className="card">
     
         <div className="row">
            <div className="col-xl-12">
            <h4 className="mt-0 header-title">JOB DETAILS</h4>
            </div>
         </div>
         <div className="row mt-3">
       
         <div className="col-xl-4">
               <div className="card">
                 
                     <h4 className="mt-0 header-title">CURRENT ORGANISATION<span className="inputImportantRed">*</span></h4>
                     <div className="form-group">
                        <input type="text"    value={currentorg}  onChange={e=>setCurrentorg(e.target.value)} placeholder="Enter Current Organisation" className="form-control" name="currentorg" id="currentorg"  tabIndex="1" required />   
                     </div>
                 
               </div>
            </div>
           
            <div className="col-xl-4">
               <div className="card">
                 
                     <h4 className="mt-0 header-title">CURRENT NET IN HAND(MONTHLY)<span className="inputImportantRed">*</span></h4>
                     <div className="form-group">
                        <input type="text" onKeyPress={e=>current(e)}   value={currentnet}  onChange={e=>setCurrentnet(e.target.value)} placeholder="Enter Current Net In Hand(Monthly)" className="form-control" name="currentnet" id="currentnet"  tabIndex="1" required />   
                     </div>
                  
               </div>
            </div>
           
            <div className="col-xl-4">
               <div className="card">
                 
                     <h4 className="mt-0 header-title">PROPOSED NET IN HAND(MONTHLY)<span className="inputImportantRed">*</span></h4>
                     <div className="form-group">
                        <input type="text"  onKeyPress={e=>current(e)}  value={proposednet}  onChange={e=>setProposednet(e.target.value)} placeholder="Enter Proposed Net In Hand(Monthly)" className="form-control" name="proposednet" id="proposednet"  tabIndex="1" required />   
                     </div>
                  
               </div>
            </div>

            <div className="col-xl-4">
               <div className="card">
                     <h4 className="mt-0 header-title">REPLACEMENT/NON-BUDGET<span className="inputImportantRed">*</span></h4>
                     <div className="form-group">
                        <select className="form-control" name="budgate" value={budgate} onChange={changeHandler} tabIndex="11" required >
                           <option value="">Select Replacement/Non-Budget</option>
                           <option value="Replacement" >Replacement</option>
                           <option value="Non-Budget">Non-Budget</option>
                        </select>  
                     </div>
               </div>
            </div>
        
            {budgate == 'Replacement'  ? (
           <div className="col-xl-4">
           <div className="card">
                 <h4 className="mt-0 header-title">REPLACEMENT EMPLOYEE CODE<span className="inputImportantRed">*</span></h4>
                 <div className="form-group">
                 <input type="text"   value={replacecode}  onChange={e=>setReplacecode(e.target.value)} placeholder="Replacement Employee Code" className="form-control" name="replacecode" id="replacecode"  tabIndex="1" />   
                 </div>
           </div>
        </div>

      
        ) : null}

      {budgate == 'Replacement' ?(
           <div className="col-xl-4">
           <div className="card">
                 <h4 className="mt-0 header-title">REPLACEMENT EMPLOYEE NAME<span className="inputImportantRed">*</span></h4>
                 <div className="form-group">
                  <input type="text"   value={replacename}  onChange={e=>setReplacename(e.target.value)} placeholder="Replacement Employee Name" className="form-control" name="replacename" id="replacename"  tabIndex="1" />  
                 </div>
              
           </div>
        </div>     

      
        ) : null}


           
            <div className="col-xl-4">
               <div className="card">
                  
                     <h4 className="mt-0 header-title">WEEKLY OFF<span className="inputImportantRed">*</span></h4>
                     <div className="form-group">
                     <select className="form-control" name="weeklyoff" value={weeklyoff} onChange={e=>setWeeklyoff(e.target.value)} tabIndex="11" required >
                           <option value="">Select Weekly Off</option>
                           <option value="Monday" >Monday</option>
                           <option value="Tuesday">Tuesday</option>
                           <option value="Wednesday">Wednesday</option>
                           <option value="Thursday">Thursday</option>
                           <option value="Friday">Friday</option>
                           <option value="Saturday">Saturday</option>
                           <option value="Sunday">Sunday</option>
                        </select>  
                        
                     </div>
                  
               </div>
            </div>

            <div className="col-xl-4">
               <div className="card">
                 
                     <h4 className="mt-0 header-title">SHIFT TIMING<span className="inputImportantRed">*</span></h4>
                     <div className="form-group">
                        <input type="text"    value={shift}  onChange={e=>setShift(e.target.value)} placeholder="Enter your Shift Timing" className="form-control" name="shift" id="shift"  tabIndex="1" required />   
                     </div>
                  </div>
              
            </div>
          </div>
         </div>
      </div>
      
      <div className="col-lg-12">
         <div className="card">
            <div className="row">
               <div className="col-xl-12">
               <h4 className="mt-0 header-title">OTHER DETAILS  </h4>
            </div>
         </div>
         <div className="row mt-3">
            <div className="col-xl-4">
               <div className="card">
                  
                     <h4 className="mt-0 header-title">REFERRED BY<span className="inputImportantRed">*</span></h4>
                     <div className="form-group">
                        <input type="text" onKeyPress={e=>NameValidation(e)}   value={referredby}  onChange={e=>setReferredby(e.target.value)} placeholder="Enter Referred By" className="form-control" name="referredby" id="referredby"  tabIndex="1" required />   
                     </div>
                  
               </div>
            </div>
            
            <div className="col-xl-4">
               <div className="card">
                  
                     <h4 className="mt-0 header-title">MANAGER NAME<span className="inputImportantRed">*</span></h4>
                     <div className="form-group">
                        <input type="text" onKeyPress={e=>NameValidation(e)}   value={manager}  onChange={e=>setManager(e.target.value)} placeholder="Enter Manager Name" className="form-control" name="manager" id="manager"  tabIndex="1" required />   
                     </div>
                 
               </div>
            </div>
         </div>
         <div className="col-xl-6 text-right">
               <div className="form-group mb-0">
                  <div>
                  {btnLoader ? (
          <button type="submit" className="btn btn-primary waves-effect waves-light" disabled="disabled">
          <i
              className="fa fa-refresh fa-spin"
              style={{ marginRight: "5px" }}
            /> Submit
          </button> 
      ) : (
         <button type="submit" className="btn btn-primary waves-effect waves-light">
                     Submit
                     </button> 
      )}
                  
                     &nbsp; &nbsp;                                           
                     <button type="button" onClick={resetForm} className="btn btn-secondary waves-effect m-l-7">Cancel</button>
                    
                  </div>
               </div>
            </div>
      </div>
   </div>

   </div>
</div>
</div>
</div>
         </div>
         </form>
      </div>
   </div>

   <footer className="footer">
      <div className="container-fluid">
         <div className="row">
         <div className="col-12">
         Celio Internaluse by <a href="https://www.celio.in/" target="_blank" style={{fontWeight: 'bold'}}>Celio</a> © {ddyear}. All Rights Reserved                        
                    </div>
         </div>
      </div>
   </footer>

 
      </>
    );
};

export default EditOnboarding;