import React from "react";
const Footer=()=>
{
    const d = new Date();
    let year = d.getFullYear(); 
   return (
    <footer className="footer">
    <div className="container-fluid">
        <div className="row">
            <div className="col-12">
                Celio Internaluse by <a href="https://www.celio.in/" target="_blank" style={{fontWeight: 'bold'}}>Celio</a> © {year}. All Rights Reserved
            </div>
        </div>
    </div>
</footer>
   );
}

export default Footer;