import React from 'react';
import Routes from './routes/Routes';
import { BrowserRouter, Route, Switch, HashRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch,useSelector} from 'react-redux'
import {toastConstant,apiConstant} from './customRedux/constants/actionConstant'
import {apiAction,toastAction,toastActionAlert} from './customRedux/actions/Actions'


export const infoToast = (message,position,theme) =>
{
    if(message!=''&&message!=null)
    {
        toast.info(message, {
            position:position,
            autoClose: process.env.REACT_APP_TOAST_AUTOCLOSE,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:theme,
            });
    }
  
}


export const successToast = (message,position,theme) =>
{
    if(message!=''&&message!=null)
    {
        toast.success(message, {
            position:position,
            autoClose: process.env.REACT_APP_TOAST_AUTOCLOSE,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:theme,
            });
    }
}
    

export const warnToast = (message,position,theme) =>
{
    if(message!=''&&message!=null)
    {
        toast.warn(message, {
            position:position,
            autoClose: process.env.REACT_APP_TOAST_AUTOCLOSE,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: theme,
            });
        }
}
        

export const errorToast = (message,position,theme) =>
{
    if(message!=''&&message!=null)
    {
    toast.error(message, {
        position:position,
        autoClose: process.env.REACT_APP_TOAST_AUTOCLOSE,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: theme,
        });
    }
}
       

export const defaultToast = (message,position,theme) =>
{
    if(message!=''&&message!=null)
    {
        toast(message, {
            position:position,
            autoClose: process.env.REACT_APP_TOAST_AUTOCLOSE,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: theme,
            });
    }
}


const App = () => {
    const reduxState=useSelector(state=>state);
    const dispatch=useDispatch();
    return (
    <div>
         <Routes />
        <ToastContainer
position={reduxState.position}
autoClose={process.env.REACT_APP_TOAST_AUTOCLOSE}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme={reduxState.theme}
/>
    </div>
   
    
    );
};

export default App;
