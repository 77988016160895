import React from "react";
import { BrowserRouter, Route, Switch, HashRouter,Redirect } from 'react-router-dom';
import ManagerComponent from '../index'
const ManagerRoute=()=>
{ return (
        <Switch>
            <Route  path="/corporate/manager/"  component={ManagerComponent} />
            <Route path='*' render={() => (<Redirect to="/pagenotfound" />)}  />
        </Switch>
    );

}
export default ManagerRoute;