import React,{ useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux'
import { BrowserRouter, Route, Switch, HashRouter } from 'react-router-dom';
import Login from '../common/Login';
import RegistrationSuccess from '../common/success';
import Newregistration from '../new-registration/Newregistration'
import CorporateRoute from '../corporate/corporateRoute'
import CorporateLogin from '../corporate/CorporateLogin'
import AdminRouteProduction from '../routes/AdminRouteProduction'
import ManagerRouteProduction from './ManagerRouteProduction';

import PageNotFound from '../extraPages/pagenotfound'
import UnauthorizedPage from '../extraPages/unautherized'
import OnBoarding from '../corporate/Manager/onboardview/NewOnBoarding';
import ManagerRoute from '../corporate/Manager/route/ManagerRoute';
const Routes = () => {
    const reduxState=useSelector(state=>state);
    const dispatch=useDispatch();
   
    return (
        <BrowserRouter basename=''>
            <HashRouter>
                
                <Switch>
                   <Route exact path="/"  component={CorporateLogin} />
                   <Route exact path="/employee"  component={Login} />
                   <Route exact path='/newemployment' component={Newregistration}/>
                   {/* <Route exact path='/onboarding' component={OnBoarding}/> */}

                   <Route exact path="/success"  component={RegistrationSuccess} />
                   <Route exact path="/pagenotfound"  component={PageNotFound} />
                   <Route exact path="/unauthorized"  component={UnauthorizedPage} />
                   <Route exact path="/corporate"  component={CorporateLogin} />
                   <Route exact path="/corporate/login"  component={CorporateLogin} />
                   <Route  path='/corporate/admin/'>
                    <AdminRouteProduction isLoggedIn={reduxState.userReducer.isLoggedIn} roleUID={reduxState.userReducer.roleUID}>
                         <CorporateRoute /> 
                    </AdminRouteProduction>
                   </Route>
                   <Route  path='/corporate/manager/'>
                    <ManagerRouteProduction isLoggedIn={reduxState.userReducer.isLoggedIn} roleUID={reduxState.userReducer.roleUID}>
                        <ManagerRoute/>
                    </ManagerRouteProduction>
                   
                   </Route>
                   <Route path='*' component={PageNotFound} />
                   
                </Switch>
            </HashRouter>
        </BrowserRouter>

    );
};

export default Routes;