import React,{ useState,useEffect} from "react";
import {useDispatch,useSelector} from 'react-redux'
import {apiAction,toastAction,toastActionAlert,userAction} from '../../customRedux/actions/Actions'
import {toastConstant,apiConstant,userConstant,HRuserConstant} from '../../customRedux/constants/actionConstant'
import {apiPost,apiFormDatePost} from '../../api/apiCommon'
import { useHistory,useParams  } from "react-router-dom";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'
import { saveAs } from 'file-saver'
import { ColorRing } from  'react-loader-spinner'



const EmployeeView =()=>
{
    const [isVisible,setIsVisible]=useState(true);

    const { id } = useParams();
    const reduxState=useSelector(state=>state);
    const dispatch=useDispatch();
    const history = useHistory();
    const [rowData, setrowData] = useState({});
    const [imageDataURL, setimageDataURL] = useState('');
    //const [filePath, setFilePath] = useState(process.env.REACT_APP_FILE_URL);
    const [showModelViewer,setShowModelViewer]=useState();
    const [imgViewFile, setImgViewFile] = useState(null);
    const [resumeFlag, setResumeFlag] = useState(0);
    
    const [videoViewer, setVideoViewer] = useState(false);
    const [videoViewFile, setVideoViewFile] = useState(null);

    const openVIDEOModel=(name)=>
    {
       
          console.log(name);
          setVideoViewFile(name);
       
       setVideoViewer(true);
    }
    const [storevideoFile, setstorevideoFile] = useState(null);
    const storevideoFileChange = (file) => {
       if(file.length!=0){setstorevideoFile(file)};
    };

    const handleClick=(URL)=>
  {
    setImgViewFile(URL);
   // setImgViewFile(true);
    setShowModelViewer(true);
  
  }

  const downloadImage = async (url, name) => {
   
    saveAs(url, name)

    
  }
    useEffect(() => {
        getEmpList();
        getResumeList();
      
      }, []);

      const getEmpList=async ()=>
      {
       
        try{
            
            const response= await   apiPost('admin/getemployeebyid', {userUID:reduxState.userReducer.userUID,
            roleUID:reduxState.userReducer.roleUID,
                    id:id});
            if(!response.data.error)
            {
                setrowData(response.data.empDetails[0]);
                setimageDataURL(response.data.ImageURL);
                setIsVisible(false);

                /* console.log('details',response.data.empDetails) */
                
            }
            else
            { 
                dispatch(toastAction(toastConstant.setToast,response.data.errorMessage));
                dispatch(toastAction(toastConstant.errorToast));
            }
           
         }
         catch(error) {
               dispatch(toastAction(toastConstant.setToast,'Internal Server Error'));
               dispatch(toastAction(toastConstant.errorToast));
               console.log(error);
        }
      }
      const [resData, setresData] = useState({});

      const getResumeList=async ()=>
      {
       
        try{
            
            const response= await   apiPost('admin/getresumebyid', {userUID:reduxState.userReducer.userUID,
            roleUID:reduxState.userReducer.roleUID,
                   id:parseInt(id) });
            if(!response.data.error)
            {
                setresData(response.data.empDetails);
                setimageDataURL(response.data.ImageURL);
                setResumeFlag((response.data.empDetails.length>0)?1:0)
            
            }
            else
            { 
                /* dispatch(toastAction(toastConstant.setToast,response.data.errorMessage));
                dispatch(toastAction(toastConstant.errorToast)); */
            }
           
         }
         catch(error) {
              /*  dispatch(toastAction(toastConstant.setToast,'Internal Server Error'));
               dispatch(toastAction(toastConstant.errorToast));
               console.log(error); */
        }
      }
   
   return(
    <div className="wrapper">
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',position:'absolute',left:'47%',height:'40vh',zIndex:'999999' }}>
    <ColorRing
    visible={isVisible}
    height="80"
    width="80"
    ariaLabel="blocks-loading"
    wrapperStyle={{}}
    wrapperClass="blocks-wrapper"
    colors={['#283179','#283179','#283179','#283179','#283179']}
    style={{left:'50%', top:'50%'}}
    />
    </div>
    <div className={`container-fluid ${isVisible ? 'blur' : ''}`}>

       
    <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            
                            <h4 className="page-title">View Employee Details</h4>
                            
                            <a href="#" onClick={e=>history.goBack()} style={{margin:'1%', float:'right'}} className="btn btn-sm btn-primary">Back</a>
                        </div>
                    </div>
                </div>
       
        <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                            <h4 className="mt-0 header-title">Personal Details </h4>
                            <div className="row">
                                    <div className="col-md-2">
                                        <div className="p-20">
                                                <div className="form-group">
                                                    <label>Name : </label>
                                                </div>
                                                <div className="form-group">
                                                    <label>Parents Contact no :</label>
                                                </div>
                                               
                                                <div className="form-group">
                                                    <label>Permanent Address :</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>Pincode :</label>
                                                </div>

                                                <div className="form-group">
                                                    <label>Function :</label>
                                                </div>
                                                
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="p-20">
                                                <div className="form-group">
                                                    <label>{rowData.name}</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>{rowData.pmobile}</label>
                                                </div>
                                               
                                                <div className="form-group">
                                                    <label>{rowData.address}</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>{rowData.pincode}</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>{rowData.func}</label>
                                                </div>
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="p-20">
                                                <div className="form-group">
                                                    <label>Date Of Birth :</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>Pan Card no :</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>Current Organisation :</label>
                                                </div>
                                                <div className="form-group">
                                                     <label>State :</label>
                                                </div>
                                               
                                               
                                        </div>
                                    </div>


                                    <div className="col-md-2">
                                        <div className="p-20">
                                                <div className="form-group">
                                                    <label>{rowData.DOB}</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>{rowData.pan}</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>{rowData.previousExperience}</label>
                                                </div>
                                                <div className="form-group">
                                                 <label>{rowData.stateName}</label>
                                                  </div>
                                                
                                                
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="p-20">
                                                <div className="form-group">
                                                <label>Contact no :</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>AADHAR No :</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>Personal Email :</label>
                                                </div>
                                               
                                                <div className="form-group">
                                                    <label>Expected Join Date :</label>
                                                </div>
                                               
                                               
                                        </div>
                                    </div> 

                                    <div className="col-md-2">
                                        <div className="p-20">
                                                <div className="form-group">
                                                <label>{rowData.mobile}</label>
                                                </div>
                                                <div className="form-group">
                                                    <label>{rowData.aadhar}</label>
                                                </div>
                                                <div className="form-group">
                                            <label style={{width: '100%'}}>{rowData.personalEmail}</label>
                                                </div>
                                               
                                                        <div className="form-group">
                                                       <label>{rowData.EOJ}</label>
                                                       </div>
                                               
                                              
                                        </div>
                                    </div> 
                                </div> 
                            </div>
                        </div>
                    </div> 

                  
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                            <h4 className="mt-0 header-title">Account Details </h4>
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="p-20">
                                            <div className="form-group m-b-0">
                                                <label>Account No :</label>
                                            </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>{rowData.accountNumber}</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>IFSC Code :</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>{rowData.ifscCode}</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>Branch Name :</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                            <div className="form-group m-b-0">
                                                <label>{rowData.branchName}</label>
                                            </div>
                                        </div> 
                                    </div> 
                                </div> 
                            </div> 
                        </div> 
                    
                    </div> 

                    
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                            <h4 className="mt-0 header-title">Education Details </h4>
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="p-20">
                                            <div className="form-group m-b-0">
                                                <label>Latest Education :</label>
                                            </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>{rowData.latestEdu}</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>Passed Out :</label>
                                                </div>
                                        </div> 
                                    </div> 
                                    <div className="col-md-2">
                                        <div className="p-20">
                                        <div className="form-group m-b-0">
                                                    <label>{rowData.passedOut}</label>
                                                </div>
                                        </div> 
                                    </div> 
                                  
                                </div> 
                            </div> 
                        </div> 
                    
                    </div> 
                    </div>

                    
                    <div className="col-lg-12">
                        <div className="card" >
                        <div className="card-body " >
                                <h4 className="mt-0 header-title">File Uploads</h4>                               
                        <div className="row" >
                        {rowData.panImage!=null?(<div className="col-xl-4" >
                                <div className="card" >
                                    <div className="card-body" >
                                        <h4 className="mt-0 header-title">Pan Image</h4>                                        
                                        <img className="img-thumbnail w-100" style={{maxHeight: '300px',minHeight:'300px'}}  alt={imageDataURL+'/panImage/'+rowData.panImage} src={imageDataURL+'panImage/'+rowData.panImage} data-holder-rendered="true" />
                                        <br/>
                                       <div>
                                       <div className="text-left" style={{marginTop: '10px'}} >
                                        <a   onClick={()=>handleClick(imageDataURL+'panImage/'+rowData.panImage)} target="_blank" className="btn btn-sm btn-info">View </a>
                                    </div>
                                    <div className="text-left" style={{marginTop: '-30px',marginLeft:'70px'}}>
                                         <button onClick={()=>downloadImage(imageDataURL+'panImage/'+rowData.panImage,rowData.panImage)}   className="btn btn-sm btn-info" download>Download</button>
                                         
                                    </div>
                                       </div>
                                    </div>
                                     
                                </div>
                            </div>):(<></>)

                            }
                            {rowData.aadharFrontImage!=null?(<div className="col-xl-4">
                                <div className="card">
                                    <div className="card-body" >
                                        <h4 className="mt-0 header-title">AADHAR front Image </h4>                                        
                                        <img className="img-thumbnail w-100" style={{maxHeight: '300px',minHeight:'300px'}} alt={imageDataURL+'/aadharFrontImage/'+rowData.aadharFrontImage} src={imageDataURL+'/aadharFrontImage/'+rowData.aadharFrontImage} data-holder-rendered="true" />
                                        <br/>
                                       <div>
                                       <div className="text-left" style={{marginTop: '10px'}}>
                                        <a  onClick={()=>handleClick(imageDataURL+'/aadharFrontImage/'+rowData.aadharFrontImage)} target="_blank" className="btn btn-sm btn-info" >View</a>
                                    </div>
                                    <div className="text-left" style={{marginTop: '-30px',marginLeft:'70px'}}>
                                    <button onClick={()=>downloadImage(imageDataURL+'/aadharFrontImage/'+rowData.aadharFrontImage,rowData.aadharFrontImage)}   className="btn btn-sm btn-info" download>Download</button>
                                       
                                    </div>
                                       </div>
                                    </div>
                                     
                                </div>
                            </div>):(<></>)

                            }
                               {rowData.aadharFrontImage!=null?(<div className="col-xl-4">
                                <div className="card">
                                    <div className="card-body" >
                                    <h4 className="mt-0 header-title">AADHAR Back Image </h4>                                       
                                    <img className="img-thumbnail w-100" style={{maxHeight: '300px',minHeight:'300px'}} alt={imageDataURL+'/aadharBackImage/'+rowData.aadharBackImage} src={imageDataURL+'/aadharBackImage/'+rowData.aadharBackImage} data-holder-rendered="true" />
                                        <br/>
                                       <div>
                                       <div className="text-left" style={{marginTop: '10px'}}>
                                        <a  onClick={()=>handleClick(imageDataURL+'/aadharBackImage/'+rowData.aadharBackImage)} target="_blank" className="btn btn-sm btn-info" >View</a>
                                    </div>
                                    <div className="text-left" style={{marginTop: '-30px',marginLeft:'70px'}}>
                                    <button onClick={()=>downloadImage(imageDataURL+'/aadharBackImage/'+rowData.aadharBackImage,rowData.aadharBackImage)}   className="btn btn-sm btn-info" download>Download</button>
                                       
                                    </div>
                                       </div>
                                    </div>
                                     
                                </div>
                            </div>):(<></>)

                            }


                            {rowData.video!=null?(<div className="col-xl-4" >
                                <div className="card" >
                                    <div className="card-body" >
                                        <h4 className="mt-0 header-title">Store Video</h4>                                        
                                        <video className="img-thumbnail w-100" style={{maxHeight: '300px',minHeight:'300px'}}  alt={imageDataURL+'storevideoFile/'+rowData.video} src={imageDataURL+'storevideoFile/'+rowData.video} data-holder-rendered="true" />
                                        <br/>
                                       <div>
                                       <div className="text-left" style={{marginTop: '10px'}} >
                                        <a   onClick={()=>openVIDEOModel(imageDataURL+'storevideoFile/'+rowData.video)} target="_blank" className="btn btn-sm btn-info">View </a>
                                    </div>
                                    <div className="text-left" style={{marginTop: '-30px',marginLeft:'70px'}}>
                                         <button onClick={()=>downloadImage(imageDataURL+'storevideoFile/'+rowData.video,rowData.video)}   className="btn btn-sm btn-info" download>Download</button>
                                         
                                    </div>
                                       </div>
                                    </div>
                                     
                                </div>
                            </div>):(<></>)

                            }

{rowData.certificate!=null?(<div className="col-xl-6">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="mt-0 header-title">Previous Experience Letter </h4>                                        
                                        
                                        <button onClick={()=>downloadImage(imageDataURL+'/certificateFile/'+rowData.certificate,rowData.certificate)}   className="btn btn-sm btn-info" download>{rowData.certificate}</button>
                                       </div>
                                    </div>
                                     
                                </div>
                            ):(<></>)
                            }
                            
                            {rowData.previousOffer!=null?(<div className="col-xl-6">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="mt-0 header-title">Previous Offer Letter </h4>                                        
                                        
                                        <button onClick={()=>downloadImage(imageDataURL+'/offerLetter/'+rowData.previousOffer,rowData.previousOffer)}   className="btn btn-sm btn-info" download>{rowData.previousOffer}</button>
                                       </div>
                                    </div>
                                     
                                </div>
                            ):(<></>)
                            }
                            
                           
{rowData.previousExperienceDoc!=null?(<div className="col-xl-6">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="mt-0 header-title">Previous Experience Letter </h4>                                        
                                        
                                        <button onClick={()=>downloadImage(imageDataURL+'/experienceLetter/'+rowData.previousExperienceDoc,rowData.previousExperienceDoc)}   className="btn btn-sm btn-info" download>{rowData.previousExperienceDoc}</button>
                                       </div>
                                    </div>
                                     
                                </div>
                            ):(<></>)
                            }


                    

{rowData.payslip1!=null?(<div className="col-xl-6">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="mt-0 header-title">Payslip 1 </h4>                                        
                                        
                                        <button onClick={()=>downloadImage(imageDataURL+'/payslip/'+rowData.payslip1,rowData.payslip1)}   className="btn btn-sm btn-info" download>{rowData.payslip1}</button>
                                       </div>
                                    </div>
                                     
                                </div>
                            ):(<></>)
                            }

                          
                        
                            {rowData.payslip2!=null?(<div className="col-xl-6">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="mt-0 header-title">Payslip 2 </h4>                                        
                                        <button onClick={()=>downloadImage(imageDataURL+'/payslip/'+rowData.payslip2,rowData.payslip2)}   className="btn btn-sm btn-info" download>{rowData.payslip2}</button>
                                       </div>
                                    </div>
                                     
                                </div>
                            ):(<></>)
                            }
                            {rowData.payslip3!=null?(<div className="col-xl-6">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="mt-0 header-title">Payslip 3 </h4>                                        
                                        <button onClick={()=>downloadImage(imageDataURL+'/payslip/'+rowData.payslip3,rowData.payslip3)}   className="btn btn-sm btn-info" download>{rowData.payslip3}</button>
                                       </div>
                                    </div>
                                     
                                </div>
                            ):(<></>)
                            } 
                    
             

                        </div>
                        
                       
                      

                         
                            </div>
                            

                        </div>


                    </div>

                    {((parseInt(reduxState.userReducer.roleUID)==1||parseInt(reduxState.userReducer.roleUID)==5)&&resumeFlag==1) ? (
          <div className="row">
          <div className="col-lg-12">
              <div className="card">
                  <div className="card-body">
                  <h2 className="mt-0 header-title">Resume Details </h2>
                  <table className="table table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th className="border-top-0">ResumeTitle</th>
                                                <th className="border-top-0">ResumeFileName</th>
                                                <th className="border-top-0">Upload Date</th>
                                              
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {resData.map((e, key) => {
        return <tr  key={key}><td>{e.resumeTitle}</td><td><button onClick={()=>downloadImage(imageDataURL+'/resume/'+e.resumeFileName,e.resumeFileName)}   className="btn btn-sm btn-info" download>{e.resumeFileName}</button></td><td>{e.createdDate}</td></tr>;
    })}  
                                    
                                    </tbody>
                                       
                                      
                                    </table>   
                  </div> 
                  </div> 
              </div> 
          </div>



      ) : (<></>)}
                  
                  <Modal
          size="xl"
        show={videoViewer}
        onHide={() => setVideoViewer(false)}
        aria-labelledby="xample-modal-sizes-title-lg"
        >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
          <center>  Video Viewer</center>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <video style={{maxWidth:'100%',minWidth:'100%',height:'65vh'}}  type="video/mp4" src={videoViewFile} controls/>
        </Modal.Body>
        <Modal.Footer>
        <button   onClick={() => setVideoViewer(false)} type="button" className="btn btn-primary waves-effect waves-light">
                     Close
                     </button> 
                   
        </Modal.Footer>
      </Modal>
                    <Modal
      show={showModelViewer}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Image View
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <img style={{maxWidth:'100%',minWidth:'100%'}} src={imgViewFile} />
      </Modal.Body>
      <Modal.Footer>
      <button   onClick={() => setShowModelViewer(false)} type="button" className="btn btn-danger waves-effect waves-light">
                     Close
                     </button> 
      </Modal.Footer>
    </Modal>
            </div>
             
        </div>
   );
}
export default EmployeeView;