import React from "react";
import { BrowserRouter, Route, Switch, HashRouter,Redirect } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Dashboard from '../component/dashboard/dashboard';
import NewlyOffered from '../component/newlyoffered/newlyoffered';
import OnBoarding from "./onboarding/onboarding";
import OnboardView from "./onboardview/onboardview";
import EmployeeView from '../employeeview/employeeview';
import PageNotFound from '../../extraPages/pagenotfound'
const corporateComponent=()=>
{  return (
    <><>
        <Header />
        <Switch>
            <Route exact path="/corporate/admin/dashboard" component={Dashboard} />
            <Route exact path="/corporate/admin/reports" component={NewlyOffered} />
            <Route exact path="/corporate/admin/onboarding" component={OnBoarding} />
            <Route exact path="/corporate/admin/viewonboard/:id" component={OnboardView} />
            <Route exact path="/corporate/admin/viewemployee/:id" component={EmployeeView} />
        
            <Route path='*' render={() => (<Redirect to="/pagenotfound" />)}  />
        </Switch>
    </><Footer /></>
    
    
   );
}

export default corporateComponent;