import React from "react";
import { Route, Switch, Redirect } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Dashboard from './dashboard/dashboard';
import OnBoarding from "../Manager/onboarding/onboarding";
import OnboardView from "../Manager/onboardview/onboardview";
import NewOnBoarding from "./onboardview/NewOnBoarding";
import EditOnboarding from "../Manager/onboardview/EditOnboarding";

const ManagerComponent=()=>
{  return (
    <>
        <>
            <Header />
            <Switch>
                <Route exact path="/corporate/manager/dashboard" component={Dashboard} />
                <Route exact path="/corporate/manager/onboarding" component={OnBoarding} />
                <Route exact path="/corporate/manager/viewonboard/:id" component={OnboardView} />
                <Route exact path="/corporate/manager/editemployee/:id" component={EditOnboarding} />
                <Route exact path="/corporate/manager/newonboarding" component={NewOnBoarding} />
                <Route path='*' render={() => (<Redirect to="/pagenotfound" />)}  />
            </Switch>
        </>
        <Footer />
    </>
   );
}

export default ManagerComponent;