import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {apiReducer} from './customRedux/reducers/apiReducer'
import {notificationReducer} from './customRedux/reducers/notifictionReducer'
import {userReducer} from './customRedux/reducers/userReducer'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware,combineReducers } from 'redux'
import thunk from 'redux-thunk';
const store = createStore(combineReducers({
    apiReducer,
    notificationReducer,
    userReducer,
}), applyMiddleware(thunk))
store.subscribe(() => {
    console.log("subscribe all",store.getState())
})

ReactDOM.render(
  
    <Provider store={store}>
    <App />
  </Provider>
 
, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
