import { useState,useEffect} from "react";
import {useDispatch,useSelector} from 'react-redux'
import {userAction} from '../customRedux/actions/Actions'
import {userConstant} from '../customRedux/constants/actionConstant'
import {setLogoutUser} from '../api/apiCommon'
import { useHistory } from "react-router-dom";
import { Link} from 'react-router-dom';
import image from '../assets/img/logo.png'
import profileImg from '../assets/img/man-icon.png'
import 'react-alert-confirm/lib/style.css';
import AlertConfirm from 'react-alert-confirm';
import { apiPost } from "../api/apiCommon";
const Header=()=>
{
    const reduxState=useSelector(state=>state);
    const dispatch=useDispatch();
    const history = useHistory();
    const [activeMenu, setActiveMenu] = useState('');

    const handleMenuClick = (menu) => {
      setActiveMenu(menu);
    }
    const [defaulProfileIMG, setDefaulProfileIMG] = useState((parseInt(reduxState.userReducer.roleUID)==3)?'':profileImg);
    useEffect(() => {
        if(window.menuFunctionVariableCheck==0)
        {
            window.menuFunctionVariable.init();
        }
      
        
        window.addEventListener('resize', async function() {
           
           var innerWidth=window.innerWidth?window.innerWidth:null;
           if(innerWidth!=null)
           {
               
                if(innerWidth>=992)
                {
                    document.getElementById("navigation").style.display = "block";
                }
                else if(innerWidth<=990)
                {
                    document.getElementById("navigation").style.display = "none";
                }
           }
         });
      }, []);

      const confirmLogout=(event)=>
      {
         event.preventDefault();
        
          AlertConfirm({
            title: 'Confirm to Logout?',
            desc: 'Are you sure to do this...!',
            onOk: () => {
               Logout()
            },
            onCancel: () => {
              console.log('cancel');
            }
          });
      }

      const Logout=()=>
      {
        setLogoutUser();
        dispatch(userAction(userConstant.logoutAdminUser,{}));
        history.push("/");
      }
      useEffect(() => {
        getEmpList();
      
      }, []);
      const [rowData, setrowData] = useState({});
      const [imageDataURL, setimageDataURL] = useState('');
     
      const getEmpList=async ()=>
      {
       
        
        
          const response= await  apiPost('admin/getaddphoto',{userUID:reduxState.userReducer.userUID,
            roleUID:reduxState.userReducer.roleUID
                   });
        
            if(!response.data.error)
            {
              
                setrowData(response.data.empDetails[0]);
                setimageDataURL(response.data.ImageURL);
                setDefaulProfileIMG(response.data.ImageURL+'photo/'+response.data.empDetails[0].photoFileName);
               
                
            }
        
      }
    
   return (
    <header id="topnav">
        <div className="topbar-main">
            <div className="container-fluid">

                
                <div className="logo">
                    <a href="#" onClick={e=>e.preventDefault()} className="logo">
                    <svg xmlns="http://www.w3.org/2000/svg" width="150" height="30" fill="none" className="headerLogo" viewBox="0 0 566 150"><path fill="#010101" d="M417.65 46.04c-24.39 0-44.18 19.78-44.18 44.18s19.78 44.18 44.18 44.18 44.18-19.78 44.18-44.18c-.01-24.4-19.78-44.18-44.18-44.18Zm0 71.18c-14.92 0-27.01-12.09-27.01-27.01 0-14.92 12.1-27.01 27.01-27.01 14.91 0 27.01 12.09 27.01 27.01 0 14.92-12.1 27.01-27.01 27.01ZM198.45 105.14c-4.84 7.28-13.1 12.08-22.49 12.08-12.71 0-23.37-8.78-26.25-20.61h69.95c.3-2.09.47-4.22.47-6.4 0-24.4-19.78-44.18-44.18-44.18s-44.18 19.78-44.18 44.18 19.78 44.18 44.18 44.18c19.16 0 35.46-12.2 41.58-29.25h-19.08Zm-22.48-41.93c11.55 0 21.4 7.25 25.26 17.45H150.7c3.86-10.2 13.72-17.45 25.27-17.45ZM77.51 105.5c-4.87 7.08-13.02 11.73-22.27 11.73-14.92 0-27.01-12.09-27.01-27.01 0-14.92 12.09-27.01 27.01-27.01 9.9 0 18.56 5.34 23.26 13.29h18.73c-5.77-17.68-22.39-30.46-41.99-30.46-24.4 0-44.18 19.78-44.18 44.18s19.78 44.18 44.18 44.18c19.03 0 35.24-12.03 41.46-28.9H77.51ZM277.53 16.63h-18.84v117.08h18.84V16.63Z"></path><path fill="#ED2224" d="M518.02 41.08V16.63H531v24.45l23.13-7.59 3.98 12.27-23.13 7.59 14.3 19.69-10.51 7.59-14.3-19.68-14.21 19.68-10.51-7.59 14.13-19.69-22.96-7.59 3.97-12.27 23.13 7.59Z"></path><path fill="#010101" d="M334.93 16.61h-18.85v18.85h18.85V16.61ZM334.92 49.63h-18.84v84.08h18.84V49.63Z"></path></svg>
                        
                    </a>

                </div>
                <div className="menu-extras topbar-custom">

                <li className="list-inline-item dropdown notification-list hide-phone"  >
                    <div style={{position:"relative",left:'420px'}}className="dropdown-item noti-title1" >
                        <h6>Welcome {reduxState.userReducer.roleName} : {reduxState.userReducer.name}</h6>
                    </div>

                </li>

                    <ul className="list-inline float-right mb-0">

                         
                    
                    <li className="list-inline-item dropdown notification-list hide-phone">
                    
                 {/*  <div className="dropdown-item noti-title1" style={{backgroundClip:''}}>
                                   <h6>{reduxState.userReducer.name}</h6>
                                </div>  */}
                               
                            </li>
                        <li className="list-inline-item dropdown notification-list"> 
                        <a className="nav-link dropdown-toggle arrow-none waves-effect nav-user" data-toggle="dropdown" href="#" onClick={e=>e.preventDefault()} role="button" aria-haspopup="false" aria-expanded="false">
                        <img src={defaulProfileIMG} /* alt="user"  */className="rounded-circle" />
     
           </a>

           
                       
                            <div className="dropdown-menu dropdown-menu-right profile-dropdown ">
                              
                            {/*     <div className="dropdown-item noti-title">
                                    <h5>Welcome {reduxState.userReducer.name}</h5>
                                </div> */}
                                <a className="dropdown-item" href="#" onClick={e=>e.preventDefault()}><i className="mdi mdi-account-circle m-r-5 text-muted"></i> Profile</a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href="#" onClick={e=>confirmLogout(e)}>
                                    <i className="mdi mdi-logout m-r-5 text-muted"></i> Logout</a>

                            </div>
                        </li>
                        <li className="menu-item list-inline-item">
                          
                            <a className="navbar-toggle nav-link">
                                <div className="lines">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </a>
                            
                        </li>

                    </ul>

                </div>

             

                <div className="clearfix"></div>

            </div> 
        </div>
        

       
        <div className="navbar-custom" style={{width:'105%'}}>
            <div className="container-fluid">
                <div id="navigation">
                    
                    
                    {reduxState.userReducer.roleUID==1 ? (
                        <ul className="navigation-menu text-center">
                        <li className={activeMenu === 'dashboard' ? 'active has-submenu ' : 'has-submenu'}>
                          <Link to="/corporate/admin/dashboard" onClick={() => handleMenuClick('dashboard')}><i className="mdi mdi-airplay"></i>Dashboard</Link>
                        </li>

                        <li className={activeMenu === 'reports' ? 'active has-submenu ' : 'has-submenu'}>
                          <Link to="/corporate/admin/reports" onClick={() => handleMenuClick('reports')}><i className="mdi mdi-airplay"></i>NewEmployment</Link>
                        </li>

                        <li className={activeMenu === 'onboarding' ? 'active has-submenu ' : 'has-submenu'}>
                          <Link to="/corporate/admin/onboarding" onClick={() => handleMenuClick('onboarding')}><i className="mdi mdi-airplay"></i>OnBoarding</Link>
                        </li>
                       
                        </ul>
                    ) : ('')}
                    {reduxState.userReducer.roleUID==2 ? (
                         <ul className="navigation-menu text-center">
                         <li className={activeMenu === 'dashboard' ? 'active has-submenu ' : 'has-submenu'}>
                           <Link to="/corporate/manager/dashboard" onClick={() => handleMenuClick('dashboard')}><i className="mdi mdi-airplay"></i>Dashboard</Link>
                         </li>
 
                         {/* <li className={activeMenu === 'reports' ? 'active has-submenu ' : 'has-submenu'}>
                           <Link to="/corporate/manager/reports" onClick={() => handleMenuClick('reports')}><i className="mdi mdi-airplay"></i>NewEmployment</Link>
                         </li> */}
 
                         <li className={activeMenu === 'onboarding' ? 'active has-submenu ' : 'has-submenu'}>
                           <Link to="/corporate/manager/onboarding" onClick={() => handleMenuClick('onboarding')}><i className="mdi mdi-airplay"></i>OnBoarding</Link>
                         </li>
                        
                         </ul>
                        
                    ) : ('')}



                   
                </div> 
            </div>
        </div> 
    </header>
   );
}

export default Header;