import { Link } from "react-router-dom";

const  Login =()=>
{
    return (
        <div id="app" style={{backgroundColor: '#3852A4',minHeight:'100vh',position:'fixed',overflowY:'scroll',width:'100%'}}>
        <main className="py-4">
           <div className="container">
              <div className="wrapper-page">
                 <div className="card">
                    <div className="card-body">
                       <div className="text-center mt-2 mb-4">
                          <a href="#" className="logo logo-admin">
                          <svg xmlns="http://www.w3.org/2000/svg" width="196" height="40" fill="none" className="headerLogo" viewBox="0 0 566 150"><path fill="#010101" d="M417.65 46.04c-24.39 0-44.18 19.78-44.18 44.18s19.78 44.18 44.18 44.18 44.18-19.78 44.18-44.18c-.01-24.4-19.78-44.18-44.18-44.18Zm0 71.18c-14.92 0-27.01-12.09-27.01-27.01 0-14.92 12.1-27.01 27.01-27.01 14.91 0 27.01 12.09 27.01 27.01 0 14.92-12.1 27.01-27.01 27.01ZM198.45 105.14c-4.84 7.28-13.1 12.08-22.49 12.08-12.71 0-23.37-8.78-26.25-20.61h69.95c.3-2.09.47-4.22.47-6.4 0-24.4-19.78-44.18-44.18-44.18s-44.18 19.78-44.18 44.18 19.78 44.18 44.18 44.18c19.16 0 35.46-12.2 41.58-29.25h-19.08Zm-22.48-41.93c11.55 0 21.4 7.25 25.26 17.45H150.7c3.86-10.2 13.72-17.45 25.27-17.45ZM77.51 105.5c-4.87 7.08-13.02 11.73-22.27 11.73-14.92 0-27.01-12.09-27.01-27.01 0-14.92 12.09-27.01 27.01-27.01 9.9 0 18.56 5.34 23.26 13.29h18.73c-5.77-17.68-22.39-30.46-41.99-30.46-24.4 0-44.18 19.78-44.18 44.18s19.78 44.18 44.18 44.18c19.03 0 35.24-12.03 41.46-28.9H77.51ZM277.53 16.63h-18.84v117.08h18.84V16.63Z"></path><path fill="#ED2224" d="M518.02 41.08V16.63H531v24.45l23.13-7.59 3.98 12.27-23.13 7.59 14.3 19.69-10.51 7.59-14.3-19.68-14.21 19.68-10.51-7.59 14.13-19.69-22.96-7.59 3.97-12.27 23.13 7.59Z"></path><path fill="#010101" d="M334.93 16.61h-18.85v18.85h18.85V16.61ZM334.92 49.63h-18.84v84.08h18.84V49.63Z"></path></svg></a>
                       </div>
                       <div className="alert alert-success" id="successmsg" style={{display:'none'}}> </div>
                       <div className="px-3 pb-3">
                          <form className="form-horizontal m-t-20" action="index.html">
                             <div className="form-group row">
                                <div className="col-12">
                                   <input id="email" type="email" className="form-control " name="email"
                                      placeholder="Enter Your Email" />
                                   <input id="randomstring" name="randomstring" type="text"
                                      className="form-control"  style={{display:'none'}} />
                                </div>
                             </div>
                             <div className="form-group row">
                                <div className="col-12">
                                   <input id="otpvalue" type="text" className="form-control otpvalue "
                                      name="otpvalue"    
                                      style={{display:'none'}} placeholder="Please Enter OTP" />
                                </div>
                             </div>
                             <div className="form-group row d-none">
                                <div className="col-12">
                                   <div className="custom-control custom-checkbox">
                                      <input type="checkbox" className="custom-control-input"
                                         id="customCheck1" />
                                      <label className="custom-control-label" htmlFor="customCheck1">Remember
                                      me</label>
                                   </div>
                                </div>
                             </div>
                             <div className="form-group text-center row m-t-20">
                                <div className="col-12">
                                      <Link to="/new-employment"
                                      className="btn btn-danger btn-block waves-effect waves-light otpbutton"
                                      type="button">Send OTP</Link>
                                </div>
                             </div>
                             <div className="form-group text-center row m-t-20">
                                <div className="col-12">
                                   <button
                                      className="btn btn-danger btn-block waves-effect waves-light loginbutton"
                                      type="submit" style={{display:'none'}}>Login </button>
                                </div>
                             </div>
                             <div className="form-group m-t-10 mb-0 row d-none">
                                <div className="col-sm-7 m-t-20">
                                   <a href="pages-recoverpw.html" className="text-muted"><i
                                      className="mdi mdi-lock"></i> <small>Forgot your password
                                   ?</small></a>
                                </div>
                                <div className="col-sm-5 m-t-20">
                                   <a href="pages-register.html" className="text-muted"><i
                                      className="mdi mdi-account-circle"></i> <small>Create an account
                                   ?</small></a>
                                </div>
                             </div>
                          </form>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </main>
        </div>
    );
};

export default Login;